<template>
    <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t('introduction.title') }}
      </v-card-title>
      <v-card-text>
        <p>{{ $t("introduction.p1") }}
          <ul>
              <li>{{ $t("introduction.i1a") }}</li>
              <li>{{ $t("introduction.i1b") }}</li>
              <li>{{ $t("introduction.i1c") }}</li>
          </ul>
        </p>
        <p>{{ $t("introduction.p2") }}</p>
        <p>{{ $t("introduction.p3") }}
            <ul>
                <li>{{ $t("introduction.i2a") }} <v-icon color="success">mdi-check-circle</v-icon></li>
                <li>{{ $t("introduction.i2b") }} <v-icon color="error">mdi-close-circle</v-icon></li>
            </ul>
        </p>
        <p>
            Renaissance Arts Academy<br>
            2558 N San Fernando Road<br>
            Los Angeles, CA 90065<br>
            323-259-5700
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['enrollment/packet'].complete"
          color="primary"
          disabled
        >
          {{ $t("actions.completedDone") }}
        </v-btn>
        <v-btn
          v-else-if="$store.getters['enrollment/packet'].introduction"
          color="primary"
          disabled
        >
          {{ $t("actions.completed") }}
        </v-btn>
        <v-btn
          @click="postForm"
          color="primary"
          v-else
        >
          {{ $t("actions.agreeAndContinue") }} <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

export default {
  methods: {
    postForm: function() {
      this.$store.dispatch("enrollment/postPacket", {introduction: true})
      .then((response) => {
        this.$router.push({name: "EnrollmentScholarInfo" })
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>
