<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            flat
            dark
          >
            <v-toolbar-title>{{ $t("general.title") }}</v-toolbar-title>
            <v-spacer></v-spacer>
              <dark-button class="mr-2"></dark-button>
              <language-picker></language-picker>
          </v-toolbar>
          <v-card-text>
            <v-form
              id="loginForm"
              @submit.prevent.stop="login"
              method="POST"
              :disabled="authLoading"
            >
              <v-text-field
                :label="$t('general.email')"
                name="email"
                prepend-icon="mdi-account"
                type="text"
                v-model="form.email"
              ></v-text-field>
              <v-text-field
                :label="$t('general.password')"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="form.password"
                v-on:keyup.enter="login"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="login" color="primary" min-width="30%" :disabled="authLoading">
              <v-progress-circular v-if="authLoading"
                indeterminate
              ></v-progress-circular>
              <template v-else>
                {{ $t("general.login") }}
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DarkButton from "@/components/DarkButton.vue"
import LanguagePicker from "@/components/LanguagePicker.vue"

export default {
  metaInfo: {
    title: "Login"
  },
  name: "login",
  data: () => ({
    form: {
      email: "",
      password: "",
    },
  }),
  computed: {
    ...mapGetters("auth", ["authLoading"]),
  },
  methods: {
    login() {
      this.$store.dispatch("auth/login", this.form)
        .then( () => (this.$store.dispatch("enrollment/getPacket")) )
        .then( () => {
          if(this.$store.state["enrollment"].packet.complete) {
            return this.$router.push({name: "EnrollmentDone"})
          }
          else {
            return this.$router.push(this.$route.query.redirect || "/")
          }
        })
        .catch((error) => {
          console.log(error)
          return this.$store.dispatch("snackbar/snack", {text: this.$i18n.t("general.loginError"), color:"error"})
        })
    },
  },
  components: {
    DarkButton,
    LanguagePicker,
  }
}
</script>
