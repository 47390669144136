export default {
  methods: {
    validationRules: function(args) {
      if(!(args instanceof Array)){
        args = [args]
      }
      let r = []
      if(args.includes('required')) { r.push(v => !!v || (v===0) || this.$t("validation.required") ) }
      if(args.includes('email')) { r.push(v => !v || /^(.+@.+)?$/.test(v) || this.$t("validation.email") ) }
      if(args.includes('phone')) { r.push(v => !v || /^([0-9]{3}-[0-9]{3}-[0-9]{4}( x[0-9]+)?)?$/.test(v) || this.$t("validation.phone") ) }
      if(args.includes('notParentNumber')) { r.push(v =>  (v != this.$store.state["enrollment"].p1.phone_1 && v != this.$store.state["enrollment"].p2.phone_1) || this.$t("validation.notHomeNumber") ) }
      return r
    }
  }
}
