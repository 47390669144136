import _ from "lodash";
import EnrollmentService from "@/services/EnrollmentService";

const namespaced = true;

const initialState = () => {
  return {
    ready: false,
    packet: {
      id: null,
      introduction: false,
      scholar_info: false,
      home_address: false,
      mail_address: false,
      p1_address: false,
      p2_address: false,
      e1_address: false,
      e2_address: false,
      bb_address: false,
      pu1_address: false,
      pu2_address: false,
      medical: false,
      nslp: false,
      survey: false,
      mcd: false,
      is: false,
      signature: "",
      complete: false,
    },
    demographics: {
      id: null,
      nickname: "",
      gender: "",
    },
    home: {
      id: null,
      type: "home",
      fname: "",
      lname: "",
      relationship: "",
      street: "",
      street2: "",
      city: "",
      state: "CA",
      zip: "",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      phone_1_type: "",
      phone_2_type: "",
      phone_3_type: "",
      email_1: "",
      license: "",
      insurance: "",
    },
    mail: {
      id: null,
      type: "mail",
      fname: "",
      lname: "",
      street: "",
      street2: "",
      city: "",
      state: "CA",
      zip: "",
    },
    p1: {
      id: null,
      type: "p1",
      fname: "",
      lname: "",
      relationship: "",
      street: "",
      street2: "",
      city: "",
      state: "CA",
      zip: "",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      phone_1_type: "",
      phone_2_type: "",
      phone_3_type: "",
      email_1: "",
      license: "",
      insurance: "",
    },
    p2: {
      id: null,
      type: "p2",
      fname: "",
      lname: "",
      relationship: "",
      street: "",
      street2: "",
      city: "",
      state: "CA",
      zip: "",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      phone_1_type: "",
      phone_2_type: "",
      phone_3_type: "",
      email_1: "",
      license: "",
      insurance: "",
    },
    e1: {
      id: null,
      type: "e1",
      fname: "",
      lname: "",
      relationship: "",
      street: "",
      street2: "",
      city: "",
      state: "CA",
      zip: "",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      phone_1_type: "",
      phone_2_type: "",
      phone_3_type: "",
      email_1: "",
      license: "",
      insurance: "",
    },
    e2: {
      id: null,
      type: "e2",
      fname: "",
      lname: "",
      relationship: "",
      street: "",
      street2: "",
      city: "",
      state: "CA",
      zip: "",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      phone_1_type: "",
      phone_2_type: "",
      phone_3_type: "",
      email_1: "",
      license: "",
      insurance: "",
    },
    bb: {
      id: null,
      type: "bb",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      phone_1_type: "",
      phone_2_type: "",
      phone_3_type: "",
      email_1: "",
      email_2: "",
      email_3: "",
    },
    pu1: {
      id: null,
      type: "pu1",
      fname: "",
      lname: "",
      relationship: "",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      phone_1_type: "",
      phone_2_type: "",
      phone_3_type: "",
      license: "",
      insurance: "",
    },
    pu2: {
      id: null,
      type: "pu2",
      fname: "",
      lname: "",
      relationship: "",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      phone_1_type: "",
      phone_2_type: "",
      phone_3_type: "",
      license: "",
      insurance: "",
    },
    medical: {
      id: null,
      doctor: "",
      phone: "",
      insurance: "",
      allergies: "",
      medication: "",
    },
    nslp: {
      id: null,
      number_in_household: null,
      income_level: null,
      nslp_prior: null,
      case: null,
      case_type: null,
      case_number: null,
      active_duty: null,
      homeless_shelter: null,
      homeless_motel: null,
      homeless_doubled_up: null,
      homeless_living_with_friend: null,
      migrant: null,
      foster: null,
      prior_iep: null,
      prior_sped: null,
      prior_sped_school: null,
      prior_sped_private: null,
      prior_504: null,
      distance_internet: null,
      distance_enough_computers: null,
      covid_income: null,
      covid_housing: null,
      covid_support: null,
    },
    survey: {
      id: null,
      academic_writing: null,
      academic_reading: null,
      academic_math: null,
      academic_science: null,
      intellectual_critical_thinking: null,
      intellectual_curiosity: null,
      intellectual_communicate: null,
      intellectual_connections: null,
      artistic_dance_skills: null,
      artistic_dance_understanding: null,
      artistic_dance_appreciation: null,
      artistic_dance_ensemble: null,
      artistic_music_skills: null,
      artistic_music_understanding: null,
      artistic_music_appreciation: null,
      artistic_music_ensemble: null,
      personal_cooperation: null,
      personal_responsibility: null,
      personal_character: null,
      personal_study: null,
      challenge_arts: null,
      challenge_academics: null,
      access_humanities: null,
      access_smath: null,
      integrated_experience: null,
      engages_arts_school: null,
      engages_arts_home: null,
      distance_participate: null,
      distance_tech: null,
      distance_num_show_livestream: null,
      count_performance: null,
      count_information: null,
      count_student_led: null,
      count_field_trip: null,
      count_college: null,
      count_conference: null,
      count_reading: null,
      yn_calendar: null,
      yn_calendar_subscribe: null,
      yn_blackboard: null,
      yn_recommend: null,
      yn_chromebook: null,
      yn_hotspot: null,
      yn_enrichment: null,
      yn_parent_symposium: null,
      yn_student_conference: null,
      yn_readings: null,
      yn_online_groups: null,
      why_renarts: "",
      comments: "",
    },
  };
};

const state = initialState();

const mutations = {
  SET_READY(state, data) {
    state.ready = data;
  },
  SET_PROP(state, { prop, data }) {
    state[prop] = _.mapValues(state[prop], (value, key) =>
      Object.prototype.hasOwnProperty.call(data, key) ? data[key] : value
    );
  },
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  getPacket({ commit, rootState }, filter = {}) {
    const userId = rootState.auth.user.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    return EnrollmentService.getPacket(userId, filter)
      .then((response) => {
        commit("SET_READY", true);
        commit("SET_PROP", {
          prop: "packet",
          data: response.data.data[0] || {},
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  getScholarInfo({ commit, rootState }, filter = {}) {
    const userId = rootState.auth.user.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    const demographics = EnrollmentService.getDemographics(userId, filter);
    const home = EnrollmentService.getAddress(userId, { type: "home" });
    return Promise.all([demographics, home])
      .then((response) => {
        commit("SET_PROP", {
          prop: "demographics",
          data: response[0].data.data[0] || {},
        });
        commit("SET_PROP", {
          prop: "home",
          data: response[1].data.data[0] || {},
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  getAddress({ commit, rootState }, filter = {}) {
    const userId = rootState.auth.user.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    return EnrollmentService.getAddress(userId, filter)
      .then((response) => {
        response.data.data.forEach((record) =>
          commit("SET_PROP", { prop: record.type, data: record || {} })
        );
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  getMedical({ commit, rootState }, filter = {}) {
    const userId = rootState.auth.user.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    return EnrollmentService.getMedical(userId, filter)
      .then((response) => {
        commit("SET_PROP", {
          prop: "medical",
          data: response.data.data[0] || {},
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  getNslp({ commit, rootState }, filter = {}) {
    const userId = rootState.auth.user.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    return EnrollmentService.getNslp(userId, filter)
      .then((response) => {
        commit("SET_PROP", { prop: "nslp", data: response.data.data[0] || {} });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  getSurvey({ commit, rootState }, filter = {}) {
    const userId = rootState.auth.user.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    return EnrollmentService.getSurvey(userId, filter)
      .then((response) => {
        commit("SET_PROP", {
          prop: "survey",
          data: response.data.data[0] || {},
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  postPacket({ state, commit, rootState }, payload) {
    const userId = rootState.auth.user.id;
    const packetId = state.packet.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    return EnrollmentService.postPacket(userId, packetId, payload)
      .then((response) => {
        commit("SET_PROP", { prop: "packet", data: response.data.data || {} });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  postScholarInfo({ state, commit, rootState }, payload) {
    const userId = rootState.auth.user.id;
    const demographicsId = state.demographics.id;
    const homeId = state.home.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    const demographics = EnrollmentService.postDemographics(
      userId,
      demographicsId,
      payload.demographics
    );
    const home = EnrollmentService.postAddress(userId, homeId, payload.home);
    return Promise.all([demographics, home])
      .then((response) => {
        commit("SET_PROP", {
          prop: "demographics",
          data: response[0].data.data || {},
        });
        commit("SET_PROP", { prop: "home", data: response[1].data.data || {} });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  postAddress({ state, commit, rootState }, payload) {
    const userId = rootState.auth.user.id;
    const recordId = state[payload.type].id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    return EnrollmentService.postAddress(userId, recordId, payload)
      .then((response) => {
        commit("SET_PROP", {
          prop: response.data.data.type,
          data: response.data.data || {},
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  postMedical({ state, commit, rootState }, payload) {
    const userId = rootState.auth.user.id;
    const surveyId = state.medical.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    return EnrollmentService.postMedical(userId, surveyId, payload)
      .then((response) => {
        commit("SET_PROP", { prop: "medical", data: response.data.data || {} });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  postNslp({ state, commit, rootState }, payload) {
    const userId = rootState.auth.user.id;
    const surveyId = state.nslp.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    return EnrollmentService.postNslp(userId, surveyId, payload)
      .then((response) => {
        commit("SET_PROP", { prop: "nslp", data: response.data.data || {} });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  postSurvey({ state, commit, rootState }, payload) {
    const userId = rootState.auth.user.id;
    const surveyId = state.survey.id;
    if (!userId) {
      return Promise.reject("Not logged in");
    }
    return EnrollmentService.postSurvey(userId, surveyId, payload)
      .then((response) => {
        commit("SET_PROP", { prop: "survey", data: response.data.data || {} });
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

const getters = {
  enrollmentReady: (state) => {
    return state.ready;
  },
  packet: (state) => {
    return state.packet;
  },
  home: (state) => {
    return state.home;
  },
  mail: (state) => {
    return state.mail;
  },
  p1: (state) => {
    return state.p1;
  },
  p2: (state) => {
    return state.p2;
  },
  e1: (state) => {
    return state.e1;
  },
  e2: (state) => {
    return state.e2;
  },
  bb: (state) => {
    return state.bb;
  },
  pu1: (state) => {
    return state.pu1;
  },
  pu2: (state) => {
    return state.pu2;
  },
  medical: (state) => {
    return state.medical;
  },
  nslp: (state) => {
    return state.nslp;
  },
  survey: (state) => {
    return state.survey;
  },
  complete: (state) => {
    return state.packet.complete;
  },
};

export default {
  namespaced: namespaced,
  state,
  mutations,
  actions,
  getters,
};
