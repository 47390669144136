<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("mail.title") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("mail.info") }}
      </v-card-subtitle>
      <v-card-text>
        <v-form
          v-model="valid"
          @submit.prevent="postForm"
          ref="form"
          :disabled="$store.getters['enrollment/packet'].mail_address"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="mail.fname"
                :label="$t('address.fname')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="mail.lname"
                :label="$t('address.lname')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="mail.street"
                :label="$t('address.street')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="mail.street2"
                :label="$t('address.street2')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="mail.city"
                :label="$t('address.city')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="mail.state"
                :label="$t('address.state')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="mail.zip"
                :label="$t('address.zip')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['enrollment/packet'].complete"
          color="primary"
          disabled
        >
          {{ $t("actions.completedDone") }}
        </v-btn>
        <v-btn
          v-else-if="$store.getters['enrollment/packet'].mail_address"
          @click="editForm"
          color="primary"
          outlined
        >
          {{ $t("actions.completedEdit") }}
        </v-btn>
        <v-btn @click="postForm" color="primary" v-else>
          {{ $t("actions.verifyAndContinue") }}
          <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash";
import validationMixin from "@/mixins/validation.js";

export default {
  name: "enrollment-mail",
  data: () => ({
    loading: true,
    valid: false,
    mail: {
      id: null,
      type: "mail",
      fname: "",
      lname: "",
      street: "",
      street2: "",
      city: "",
      state: "CA",
      zip: "",
    },
  }),
  methods: {
    getData: function () {
      this.$store
        .dispatch("enrollment/getAddress", [{ type: "mail" }])
        .then(() => {
          for (const p in this.mail) {
            this.mail[p] = Object.prototype.hasOwnProperty.call(
              this.$store.state["enrollment"].mail,
              p
            )
              ? this.$store.state["enrollment"].mail[p]
              : this.mail[p];
          }
        })
        .catch((error) => console.log(error));
    },
    postForm: function () {
      if (!this.valid) {
        this.$refs.form.validate();
        this.$store.dispatch("snackbar/snack", {
          text: this.$i18n.t("validation.invalid"),
          color: "error",
        });
        return;
      }
      this.$store
        .dispatch("enrollment/postAddress", this.mail)
        .then(() => {
          this.$store.dispatch("enrollment/postPacket", { mail_address: true });
        })
        .then(() => {
          this.$router.push({ name: "EnrollmentParent1" });
        })
        .catch((error) => {
          if (error.data.errors) {
            const errors = _.reduce(
              error.data.errors,
              (result, value) => result + value + " ",
              ""
            );
            this.$store.dispatch("snackbar/snack", {
              text: errors,
              color: "error",
            });
          }
        });
    },
    editForm: function () {
      this.$store
        .dispatch("enrollment/postPacket", { mail_address: false })
        .catch((error) => {
          if (error.data.errors) {
            const errors = _.reduce(
              error.data.errors,
              (result, value) => result + value + " ",
              ""
            );
            this.$store.dispatch("snackbar/snack", {
              text: errors,
              color: "error",
            });
          }
        });
    },
  },
  mounted: function () {
    this.getData();
  },
  mixins: [validationMixin],
};
</script>
