export default {
  computed: {
    phoneTypes: function() {
      return [
        {value: "cell", text: this.$i18n.t("address.cell")},
        {value: "home", text: this.$i18n.t("address.home")},
        {value: "work", text: this.$i18n.t("address.work")}
      ];
    },
    relationshipTypes: function() {
      return [
        this.$i18n.t("relationship.parent"),
        this.$i18n.t("relationship.grandparent"),
        this.$i18n.t("relationship.step_parent"),
        this.$i18n.t("relationship.foster_parent"),
        this.$i18n.t("relationship.adult_family_member"),
        this.$i18n.t("relationship.adult_sibling"),
        this.$i18n.t("relationship.family_friend"),
        this.$i18n.t("relationship.legal_guardian"),
      ];
    },
    genderTypes: function() {
      return [
        {value: "M", text: this.$i18n.t("demographics.male")},
        {value: "F", text: this.$i18n.t("demographics.female")},
        {value: "X", text: this.$i18n.t("demographics.non_binary")}
      ];
    },
  }
}
