<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      v-if="loggedInAndReady"
      :mini-variant="mini"
      permanent
      app
      transition="slide-x-transition"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ authUser.nickname }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ authUser.user_number }}@renarts.org
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item
          v-for="item in navItemsFiltered"
          :key="item.link"
          :to="item.link"
          link
        >
          <v-list-item-icon>
            <complete-indicator :done="packet[item.done]" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="loggedInAndReady" app transition="slide-y-transition">
      <v-app-bar-nav-icon @click.stop="mini = !mini"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $t("general.title") }}</v-toolbar-title>
      <v-spacer />
      <dark-button class="mr-2" />
      <language-picker class="mr-2" />
      <logout-button />
    </v-app-bar>

    <v-main>
      <v-scroll-x-transition mode="out-in">
        <router-view></router-view>
      </v-scroll-x-transition>
    </v-main>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      bottom
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import DarkButton from "@/components/DarkButton.vue";
import LanguagePicker from "@/components/LanguagePicker.vue";
import LogoutButton from "@/components/LogoutButton.vue";
import CompleteIndicator from "@/components/CompleteIndicator.vue";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    drawer: true,
    mini: false,
    navItems: [
      {
        link: "/",
        text: "introduction.title",
        done: "introduction",
        onlyReturning: false,
      },
      {
        link: "/enrollment/scholar-info",
        text: "scholar_info.title",
        done: "scholar_info",
        onlyReturning: false,
      },
      {
        link: "/enrollment/mail",
        text: "mail.title",
        done: "mail_address",
        onlyReturning: false,
      },
      {
        link: "/enrollment/parent-1",
        text: "p1.title",
        done: "p1_address",
        onlyReturning: false,
      },
      {
        link: "/enrollment/parent-2",
        text: "p2.title",
        done: "p2_address",
        onlyReturning: false,
      },
      {
        link: "/enrollment/emergency-1",
        text: "e1.title",
        done: "e1_address",
        onlyReturning: false,
      },
      {
        link: "/enrollment/emergency-2",
        text: "e2.title",
        done: "e2_address",
        onlyReturning: false,
      },
      {
        link: "/enrollment/messaging",
        text: "bb.title",
        done: "bb_address",
        onlyReturning: false,
      },
      {
        link: "/enrollment/pick-up-1",
        text: "pu1.title",
        done: "pu1_address",
        onlyReturning: false,
      },
      {
        link: "/enrollment/pick-up-2",
        text: "pu2.title",
        done: "pu2_address",
        onlyReturning: false,
      },
      {
        link: "/enrollment/medical",
        text: "medical.title",
        done: "medical",
        onlyReturning: false,
      },
      {
        link: "/enrollment/family",
        text: "family.title",
        done: "nslp",
        onlyReturning: false,
      },
      {
        link: "/enrollment/survey",
        text: "survey.title",
        done: "survey",
        onlyReturning: true,
      },
      {
        link: "/enrollment/special-education",
        text: "mcd.title",
        done: "mcd",
        onlyReturning: false,
      },
      {
        link: "/enrollment/independent-study",
        text: "is.title",
        done: "is",
        onlyReturning: false,
      },
      {
        link: "/enrollment/signature",
        text: "signature.title",
        done: "complete",
        onlyReturning: false,
      },
    ],
  }),
  computed: {
    ...mapGetters("snackbar", [
      "snackbarText",
      "snackbarColor",
      "snackbarTimeout",
    ]),
    ...mapGetters("auth", ["authLoggedIn", "authUser"]),
    ...mapGetters("enrollment", ["packet", "enrollmentReady"]),
    navItemsFiltered: function () {
      return this.navItems.filter(
        (el) => !el.onlyReturning || !this.authUser.is_first_year
      );
    },
    loggedInAndReady: function () {
      return this.authLoggedIn && this.enrollmentReady;
    },
    snackbar: {
      get: function () {
        return this.$store.state["snackbar"].active;
      },
      set: function (value) {
        if (value) {
          this.$store.dispatch("snackbar/open");
        }
        this.$store.dispatch("snackbar/close");
      },
    },
  },
  components: {
    DarkButton,
    LanguagePicker,
    LogoutButton,
    CompleteIndicator,
  },
};
</script>

<style lang="scss"></style>
