export const namespaced = true

export const state = {
  active: false,
  text: "",
  color: undefined,
  timeout: 5000,
}

export const mutations = {
  SET_SNACK(state, newSnack) {
    if(newSnack.text.length) {
      state.text = newSnack.text
      state.color = newSnack.color || undefined
      state.timeout = newSnack.timeout || 5000
      state.active = true
    }
  },
  CLEAR_SNACKBAR(state) {
    state.active = false
    state.text = ""
    state.color = undefined
    state.timeout = 5000
  },
  OPEN(state) {
    state.active = true
  },
  CLOSE(state) {
    state.active = false
  },
}

export const actions = {
  snack({ commit }, newSnack) {
    commit("SET_SNACK", newSnack)
  },
  clear({ commit }) {
    commit("CLEAR_SNACKBAR");
  },
  open({ commit }) {
    commit("OPEN");
  },
  close({ commit }) {
    commit("CLOSE");
  },
}

export const getters = {
  snackbarActive: state => {
    return state.active;
  },
  snackbarText: state => {
    return state.text;
  },
  snackbarColor: state => {
    return state.color;
  },
  snackbarTimeout: state => {
    return state.timeout;
  },
}
