import Introduction from "@/views/enrollment/Introduction.vue"
import NotFound from "@/views/general/NotFound.vue"
import ServerError from "@/views/general/ServerError.vue"


export default [
  {
    path: "/",
    name: "Home",
    component: Introduction,
  },
  {
    path: "/server-error",
    name: "ServerError",
    component: ServerError,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  }
];
