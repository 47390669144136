import Introduction from "@/views/enrollment/Introduction.vue";
import ScholarInfo from "@/views/enrollment/ScholarInfo.vue";
import Home from "@/views/enrollment/Home.vue";
import Mail from "@/views/enrollment/Mail.vue";
import Parent1 from "@/views/enrollment/Parent1.vue";
import Parent2 from "@/views/enrollment/Parent2.vue";
import Emergency1 from "@/views/enrollment/Emergency1.vue";
import Emergency2 from "@/views/enrollment/Emergency2.vue";
import Messaging from "@/views/enrollment/Messaging.vue";
import PickUp1 from "@/views/enrollment/PickUp1.vue";
import PickUp2 from "@/views/enrollment/PickUp2.vue";
import Medical from "@/views/enrollment/Medical.vue";
import Family from "@/views/enrollment/Family.vue";
import Survey from "@/views/enrollment/Survey.vue";
import SpecialEducation from "@/views/enrollment/SpecialEducation.vue";
import IndependentStudy from "@/views/enrollment/IndependentStudy.vue";
import Signature from "@/views/enrollment/Signature.vue";
import Done from "@/views/enrollment/Done.vue";

export default [
  {
    path: "/enrollment/introduction",
    name: "EnrollmentIntroduction",
    component: Introduction,
  },
  {
    path: "/enrollment/scholar-info",
    name: "EnrollmentScholarInfo",
    component: ScholarInfo,
  },
  {
    path: "/enrollment/home",
    name: "EnrollmentHome",
    component: Home,
  },
  {
    path: "/enrollment/mail",
    name: "EnrollmentMail",
    component: Mail,
  },
  {
    path: "/enrollment/parent-1",
    name: "EnrollmentParent1",
    component: Parent1,
  },
  {
    path: "/enrollment/parent-2",
    name: "EnrollmentParent2",
    component: Parent2,
  },
  {
    path: "/enrollment/emergency-1",
    name: "EnrollmentEmergency1",
    component: Emergency1,
  },
  {
    path: "/enrollment/emergency-2",
    name: "EnrollmentEmergency2",
    component: Emergency2,
  },
  {
    path: "/enrollment/messaging",
    name: "EnrollmentMessaging",
    component: Messaging,
  },
  {
    path: "/enrollment/pick-up-1",
    name: "EnrollmentPickUp1",
    component: PickUp1,
  },
  {
    path: "/enrollment/pick-up-2",
    name: "EnrollmentPickUp2",
    component: PickUp2,
  },
  {
    path: "/enrollment/medical",
    name: "EnrollmentMedical",
    component: Medical,
  },
  {
    path: "/enrollment/family",
    name: "EnrollmentFamily",
    component: Family,
  },
  {
    path: "/enrollment/survey",
    name: "EnrollmentSurvey",
    component: Survey,
  },
  {
    path: "/enrollment/special-education",
    name: "EnrollmentSpecialEducation",
    component: SpecialEducation,
  },
  {
    path: "/enrollment/independent-study",
    name: "EnrollmentIndependentStudy",
    component: IndependentStudy,
  },
  {
    path: "/enrollment/signature",
    name: "EnrollmentSignature",
    component: Signature,
  },
  {
    path: "/enrollment/done",
    name: "EnrollmentDone",
    component: Done,
  },
];
