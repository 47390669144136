<template>
  <v-btn
    icon
    large
    @click.stop="toggleDark">
    <v-icon v-if="isDark">mdi-brightness-5</v-icon>
    <v-icon v-else>mdi-brightness-4</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "dark-button",
  data: () => ({
  }),
  computed: {
    isDark: function() {
      return this.$vuetify.theme.dark
    }
  },
  methods: {
    toggleDark: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      return;
    }
  },
  mounted() {
  }
}
</script>
