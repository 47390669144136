import _ from 'lodash'
import AuthService from "@/services/AuthService"

export const namespaced = true

export const state = {
  user: {
    id: null,
    nickname: null,
    lname: null,
    type: null,
    user_number: null,
    is_first_year: true,
    roles: [],
  },
  token: window.localStorage.getItem("token"),
  loading: false,
  error: null
};

export const mutations = {
  SET_USER(state, user) {
    state.user = _.mapValues(state.user, (value, key) => (Object.prototype.hasOwnProperty.call(user, key) ? user[key] : value))
  },
  CLEAR_USER(state) {
    window.localStorage.clear();
    state.user = {
      id: null,
      nickname: null,
      type: null,
      user_number: null,
      roles: [],
    }
    state.token = null
    state.loading = false
    state.error = null
  },
  SET_NICKNAME(state, nickname) {
    state.user.nickname = nickname
  },
  SET_TOKEN(state, token) {
    state.token = token
    window.localStorage.setItem("token", token)
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  }
};

export const actions = {
  login({ commit }, payload) {
    commit("SET_LOADING", true)
    return AuthService.login(payload)
      .then(response => {
        commit("SET_TOKEN", response.data.token)
        commit("SET_USER", response.data.user)
        commit("SET_LOADING", false)
        return Promise.resolve(response)
      })
      .catch(error => {
        commit("SET_ERROR", error.data ? (error.data.message || "Login Failed") : "Login Failed")
        commit("SET_LOADING", false)
        return Promise.reject(error)
      });
  },
  logout({ commit }) {
    commit("SET_LOADING", true)
    return AuthService.logout()
      .then(response => {
        commit("CLEAR_USER")
        commit("SET_LOADING", false)
        return Promise.resolve(response);
      })
      .catch(error => {
        commit("CLEAR_USER")
        commit("SET_LOADING", false)
        return Promise.reject(error);
      });
  },
  clear({ commit }) {
    commit("CLEAR_USER");
  },
  setNickname({ commit }, nickname) {
    commit("SET_NICKNAME", nickname);
  },
  check({ commit }) {
    commit("SET_LOADING", true);
    return AuthService.check()
      .then(response => {
        commit("SET_USER", response.data.user)
        commit("SET_LOADING", false)
        return Promise.resolve(response)
      })
      .catch(error => {
        commit("CLEAR_USER")
        commit("SET_LOADING", false)
        return Promise.reject(error)
      });
  },
};

export const getters = {
  authUser: state => {
    return state.user
  },
  authError: state => {
    return state.error
  },
  authLoading: state => {
    return state.loading
  },
  authLoggedIn: state => {
    return !!state.user.id
  }
};
