<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("family.title") }}
      </v-card-title>
      <v-card-subtitle>
        <p>{{ $t("family.info") }}</p>
      </v-card-subtitle>
      <v-card-text>
        <v-form
          v-model="valid"
          @submit.prevent="postForm"
          ref="form"
          :disabled="$store.getters['enrollment/packet'].nslp"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nslp.number_in_household"
                :label="$t('family.number_in_household')"
                :rules="validationRules('required')"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="nslp.income_level"
                :label="$t('family.income_level')"
                :rules="validationRules('required')"
                :items="incomeLevels"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.prior_nslp") }}</h3>
              <v-radio-group
                v-model="nslp.nslp_prior"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" class="text-h6">
              {{ $t("family.programs") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.case") }}</h3>
              <v-radio-group
                v-model="nslp.case"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nslp.case_type"
                :label="$t('family.case_type')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nslp.case_number"
                :label="$t('family.case_number')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" class="text-h6">
              {{ $t("family.circumstance") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.active_duty") }}</h3>
              <v-radio-group
                v-model="nslp.active_duty"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.migrant") }}</h3>
              <v-radio-group
                v-model="nslp.migrant"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.foster") }}</h3>
              <v-radio-group
                v-model="nslp.foster"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.homeless_shelter") }}</h3>
              <v-radio-group
                v-model="nslp.homeless_shelter"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.homeless_motel") }}</h3>
              <v-radio-group
                v-model="nslp.homeless_motel"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.homeless_doubled_up") }}</h3>
              <v-radio-group
                v-model="nslp.homeless_doubled_up"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.homeless_living_with_friend") }}</h3>
              <v-radio-group
                v-model="nslp.homeless_living_with_friend"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.prior_iep") }}</h3>
              <v-radio-group
                v-model="nslp.prior_iep"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.prior_sped_school") }}</h3>
              <v-radio-group
                v-model="nslp.prior_sped_school"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.prior_sped_private") }}</h3>
              <v-radio-group
                v-model="nslp.prior_sped_private"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.prior_504") }}</h3>
              <v-radio-group
                v-model="nslp.prior_504"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.distance_internet") }}</h3>
              <v-radio-group
                v-model="nslp.distance_internet"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.covid_income") }}</h3>
              <v-radio-group
                v-model="nslp.covid_income"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("family.covid_housing") }}</h3>
              <v-radio-group
                v-model="nslp.covid_housing"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['enrollment/packet'].complete"
          color="primary"
          disabled
        >
          {{ $t("actions.completedDone") }}
        </v-btn>
        <v-btn
          v-else-if="$store.getters['enrollment/packet'].nslp"
          @click="editForm"
          color="primary"
          outlined
        >
          {{ $t("actions.completedEdit") }}
        </v-btn>
        <v-btn @click="postForm" color="primary" v-else>
          {{ $t("actions.verifyAndContinue") }}
          <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash";
import validationMixin from "@/mixins/validation.js";

export default {
  name: "enrollment-nslp",
  data: () => ({
    loading: true,
    valid: false,
    nslp: {
      id: null,
      number_in_household: null,
      income_level: null,
      nslp_prior: null,
      case: null,
      case_type: null,
      case_number: null,
      active_duty: null,
      homeless_shelter: null,
      homeless_motel: null,
      homeless_doubled_up: null,
      homeless_living_with_friend: null,
      migrant: null,
      foster: null,
      prior_iep: null,
      prior_sped_school: null,
      prior_sped_private: null,
      prior_504: null,
      distance_internet: null,
      distance_enough_computers: null,
      covid_income: null,
      covid_housing: null,
      covid_support: null,
    },
  }),
  methods: {
    getData: function () {
      this.$store
        .dispatch("enrollment/getNslp")
        .then(() => {
          for (const p in this.nslp) {
            this.nslp[p] = Object.prototype.hasOwnProperty.call(
              this.$store.state["enrollment"].nslp,
              p
            )
              ? this.$store.state["enrollment"].nslp[p]
              : this.nslp[p];
          }
        })
        .catch((error) => console.log(error));
    },
    postForm: function () {
      if (!this.valid) {
        this.$refs.form.validate();
        this.$store.dispatch("snackbar/snack", {
          text: this.$i18n.t("validation.invalid"),
          color: "error",
        });
        return;
      }
      this.$store
        .dispatch("enrollment/postNslp", this.nslp)
        .then(() => {
          this.$store.dispatch("enrollment/postPacket", { nslp: true });
        })
        .then(() => {
          const forw = this.$store.state.auth.user.is_first_year
            ? "EnrollmentSpecialEducation"
            : "EnrollmentSurvey";
          this.$router.push({ name: forw });
        })
        .catch((error) => {
          if (error && error.data && error.data.errors) {
            const errors = _.reduce(
              error.data.errors,
              (result, value) => result + value + " ",
              ""
            );
            this.$store.dispatch("snackbar/snack", {
              text: errors,
              color: "error",
            });
          }
        });
    },
    editForm: function () {
      this.$store
        .dispatch("enrollment/postPacket", { nslp: false })
        .catch((error) => {
          if (error.data.errors) {
            const errors = _.reduce(
              error.data.errors,
              (result, value) => result + value + " ",
              ""
            );
            this.$store.dispatch("snackbar/snack", {
              text: errors,
              color: "error",
            });
          }
        });
    },
  },
  mounted: function () {
    this.getData();
  },
  computed: {
    incomeLevels: function () {
      return [
        { value: 0, text: "$1,632 or under" },
        { value: 1, text: "$1,633 to $2,215" },
        { value: 2, text: "$2,216 to $2,789" },
        { value: 3, text: "$2,790 to $3,152" },
        { value: 4, text: "$3,153 to $3,380" },
        { value: 5, text: "$3,381 to $3,963" },
        { value: 6, text: "$3,964 to $4,546" },
        { value: 7, text: "$4,547 to $4,810" },
        { value: 8, text: "$4,811 to $5,129" },
        { value: 9, text: "$5,130 to $5,640" },
        { value: 10, text: "$5,641 to $6,469" },
        { value: 11, text: "$6,470 or above" },
      ];
    },
  },
  mixins: [validationMixin],
};
</script>
