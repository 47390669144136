<template>
  <v-btn
    raised
    @click="toggleLang()"
    width="100px"
  >
    {{ otherLang }}
  </v-btn>
</template>

<script>
export default {
  name: "language-picker",
  data: () => ({
    value: false,
  }),
  methods: {
    toggleLang: function() {
      if(this.$i18n.locale == "en") {
        this.$i18n.locale = "es"
      }
      else {
        this.$i18n.locale = "en"
      }
    },
  },
  computed: {
    otherLang: function() {
      if(this.$i18n.locale == "en") {return "Español"}
      return "English"
    }
  }
}
</script>
