import Vue from "vue"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#B15DFF",
        secondary: "#72DEFF",
        success: "#1EB980",
        warning: "#FF6859",
        error: "#FF6859",
        info: "#FFCF44",
      },
      light: {
        primary: "#344955",
        secondary: "#F9AA33",
        success: "#1EB980",
        warning: "#FF6859",
        error: "#FF6859",
        info: "#FFCF44",
      },
    },
  },
});
