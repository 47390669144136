<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("mcd.title") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("mcd.info") }}
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-btn
              href="https://drive.google.com/file/d/1nrS8UpYSTQ0CAxk6WDKcjNzU2rwMOkzm/view"
              target="_blank"
              outlined
            >
              <v-icon>mdi-pdf-box</v-icon> {{ $t("mcd.link-1") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              href="https://drive.google.com/file/d/19jZbA7YFhQuZezn-DMNSix5JwXNZ3kqp/view"
              target="_blank"
              outlined
            >
              <v-icon>mdi-pdf-box</v-icon> {{ $t("mcd.link") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['enrollment/packet'].mcd"
          color="primary"
          disabled
        >
          {{ $t("actions.completed") }}
        </v-btn>
        <v-btn @click="postForm" color="primary" v-else>
          {{ $t("actions.spedAndContinue") }}
          <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "enrollment-mcd",
  data: () => ({
    loading: true,
  }),
  methods: {
    postForm: function () {
      this.$store
        .dispatch("enrollment/postPacket", { mcd: true })
        .then(() => {
          this.$router.push({ name: "EnrollmentIndependentStudy" });
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
