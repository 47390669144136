<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("e1.title") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("e1.info") }}
      </v-card-subtitle>
      <v-card-text>
        <v-form
          v-model="valid"
          @submit.prevent="postForm"
          ref="form"
          :disabled="$store.state['enrollment'].packet.e1_address"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="e1.fname"
                :label="$t('address.fname')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="e1.lname"
                :label="$t('address.lname')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-model="e1.relationship"
                :items="relationshipTypes"
                :label="$t('address.relationship')"
                :rules="validationRules('required')"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="e1.street"
                :label="$t('address.street')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="e1.street2"
                :label="$t('address.street2')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="e1.city"
                :label="$t('address.city')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="e1.state"
                :label="$t('address.state')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="e1.zip"
                :label="$t('address.zip')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" class="text-h6">
              {{ $t("e1.contact") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                v-model="e1.phone_1_type"
                :items="phoneTypes"
                :label="$t('address.phone_1_type')"
                :rules="validationRules('required')"
              ></v-select>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="e1.phone_1"
                :label="$t('address.phone_1')"
                :rules="validationRules(['required', 'phone'])"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                v-model="e1.phone_2_type"
                :items="phoneTypes"
                :label="$t('address.phone_2_type')"
              ></v-select>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="e1.phone_2"
                :label="$t('address.phone_2')"
                :rules="validationRules(['phone'])"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                v-model="e1.phone_3_type"
                :items="phoneTypes"
                :label="$t('address.phone_3_type')"
              ></v-select>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="e1.phone_3"
                :label="$t('address.phone_3')"
                :rules="validationRules(['phone'])"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="e1.email_1"
                :label="$t('address.email_1')"
                :rules="validationRules(['email'])"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" class="text-h6">
              {{ $t("e1.car") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="e1.license"
                :label="$t('address.license')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="e1.insurance"
                :label="$t('address.insurance')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['enrollment/packet'].complete"
          color="primary"
          disabled
        >
          {{ $t("actions.completedDone") }}
        </v-btn>
        <v-btn
          v-else-if="$store.getters['enrollment/packet'].e1_address"
          @click="editForm"
          color="primary"
          outlined
        >
          {{ $t("actions.completedEdit") }}
        </v-btn>
        <v-btn @click="postForm" color="primary" v-else>
          {{ $t("actions.verifyAndContinue") }}
          <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash";
import validationMixin from "@/mixins/validation.js";
import typesMixin from "@/mixins/types.js";

export default {
  name: "enrollment-e1",
  data: () => ({
    loading: true,
    valid: false,
    e1: {
      id: null,
      type: "e1",
      fname: "",
      lname: "",
      relationship: "",
      street: "",
      street2: "",
      city: "",
      state: "CA",
      zip: "",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      phone_1_type: "",
      phone_2_type: "",
      phone_3_type: "",
      email_1: "",
      license: "",
      insurance: "",
    },
  }),
  methods: {
    getData: function () {
      this.$store
        .dispatch("enrollment/getAddress", [{ type: "e1" }])
        .then(() => {
          for (const p in this.e1) {
            this.e1[p] = Object.prototype.hasOwnProperty.call(
              this.$store.state["enrollment"].e1,
              p
            )
              ? this.$store.state["enrollment"].e1[p]
              : this.e1[p];
          }
        })
        .catch((error) => console.log(error));
    },
    postForm: function () {
      if (!this.valid) {
        this.$refs.form.validate();
        this.$store.dispatch("snackbar/snack", {
          text: this.$i18n.t("validation.invalid"),
          color: "error",
        });
        return;
      }
      this.$store
        .dispatch("enrollment/postAddress", this.e1)
        .then(() => {
          this.$store.dispatch("enrollment/postPacket", { e1_address: true });
        })
        .then(() => {
          this.$router.push({ name: "EnrollmentEmergency2" });
        })
        .catch((error) => {
          if (error.data.errors) {
            const errors = _.reduce(
              error.data.errors,
              (result, value) => result + value + " ",
              ""
            );
            this.$store.dispatch("snackbar/snack", {
              text: errors,
              color: "error",
            });
          }
        });
    },
    editForm: function () {
      this.$store
        .dispatch("enrollment/postPacket", { e1_address: false })
        .catch((error) => {
          if (error.data.errors) {
            const errors = _.reduce(
              error.data.errors,
              (result, value) => result + value + " ",
              ""
            );
            this.$store.dispatch("snackbar/snack", {
              text: errors,
              color: "error",
            });
          }
        });
    },
  },
  mounted: function () {
    this.getData();
  },
  mixins: [validationMixin, typesMixin],
};
</script>
