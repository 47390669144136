import * as API from "./API.js";

export default {
  login(payload) {
    return API.apiClient.post("auth/login_enrollment", payload);
  },
  logout() {
    return API.apiClient.post("auth/logout");
  },
  check() {
    return API.apiClient.get("auth/check_enrollment");
  },
};
