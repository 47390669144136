import Vue from "vue"
import store from "@/store";
import VueRouter from "vue-router"
import routes from "./routes"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  const redirect = (to.name == "NotFound" || to.name == "ServerError" || to.name == "Login") ? "/" : to.fullPath
  if(to.name == from.name) {
    next(false)
  }
  if(to.name == "NotFound" || to.name == "ServerError") {
    next()
  }
  else if(!store.getters["auth/authLoggedIn"]) {
    if(to.name == "Login") {
      next()
    }
    else {
      store.dispatch("auth/check")
        .then( () => (store.dispatch("enrollment/getPacket")) )
        .then( () => (next()) )
        .catch((error) => {
          console.log(error)
          next({ name: "Login", query: { redirect: redirect } })
        })
    }
  }
  else {
    next()
  }
})
/*
router.afterEach((to) => {
  Vue.nextTick(() => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title) || "RenArts Enrollment Update";
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
  })
})
*/
export default router
