<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("survey.title") }}
      </v-card-title>
      <v-card-subtitle>
        <p>
          {{ $t("survey.info") }}
        </p>
      </v-card-subtitle>
      <v-card-text>
        <v-form
          v-model="valid"
          @submit.prevent="postForm"
          ref="form"
          :disabled="$store.getters['enrollment/packet'].survey"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <h3>{{$t('survey.access_humanities')}}</h3>
              <v-radio-group
                v-model="survey.access_humanities"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <h3>{{$t('survey.access_smath')}}</h3>
              <v-radio-group
                v-model="survey.access_smath"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <h3>{{$t('survey.integrated_experience')}}</h3>
              <v-radio-group
                v-model="survey.integrated_experience"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <h3>{{$t('survey.engages_arts_school')}}</h3>
              <v-radio-group
                v-model="survey.engages_arts_school"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <h3>{{$t('survey.engages_arts_home')}}</h3>
              <v-radio-group
                v-model="survey.engages_arts_home"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              align="center"
              class="text-h6"
            >
              {{ $t("survey.header_distance") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <h3>{{$t('survey.yn_student_conference')}}</h3>
              <v-radio-group
                v-model="survey.yn_student_conference"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <h3>{{$t('survey.yn_readings')}}</h3>
              <v-radio-group
                v-model="survey.yn_readings"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <h3>{{$t('survey.distance_num_show_livestream')}}</h3>
              <v-text-field
                v-model="survey.distance_num_show_livestream"
                single-line
                type="number"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="baseline">
            <v-col cols="12" md="12">
              <h3>{{$t('survey.s8q1')}}</h3>
              <v-radio-group
                v-model="survey.yn_calendar"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12">
              <h3>{{$t('survey.s8q2')}}</h3>
              <v-radio-group
                v-model="survey.yn_calendar_subscribe"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="12">
              <h3>{{$t('survey.s8q3')}}</h3>
              <v-radio-group
                v-model="survey.yn_blackboard"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12">
              <h3>{{$t('survey.s8q4')}}</h3>
              <v-radio-group
                v-model="survey.yn_recommend"
                row
                :rules="validationRules('required')"
              >
                <v-radio :label="$t('survey.yes')" value="Y"></v-radio>
                <v-radio :label="$t('survey.no')" value="N"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              align="center"
              class="text-h6"
            >
              {{ $t("survey.s9") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-card-text>
                <v-textarea
                  v-model="survey.why_renarts"
                  rows="5"
                  outlined
                >
                </v-textarea>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              align="center"
              class="text-h6"
            >
              {{ $t("survey.s10") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="survey.comments"
                rows="5"
                outlined
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['enrollment/packet'].complete"
          color="primary"
          disabled
        >
          {{ $t("actions.completedDone") }}
        </v-btn>
        <v-btn
          v-else-if="$store.getters['enrollment/packet'].survey"
          @click="editForm"
          color="primary"
          outlined
        >
          {{ $t("actions.completedEdit") }}
        </v-btn>
        <v-btn
          @click="postForm"
          color="primary"
          v-else
        >
          {{ $t("actions.verifyAndContinue") }} <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash'
import validationMixin from "@/mixins/validation.js"

export default {
  name: 'enrollment-home',
  data: () => ({
    valid: false,
    survey: {
      id: null,
      academic_writing: null,
      academic_reading: null,
      academic_math: null,
      academic_science: null,
      intellectual_critical_thinking: null,
      intellectual_curiosity: null,
      intellectual_communicate: null,
      intellectual_connections: null,
      artistic_dance_skills: null,
      artistic_dance_understanding: null,
      artistic_dance_appreciation: null,
      artistic_dance_ensemble: null,
      artistic_music_skills: null,
      artistic_music_understanding: null,
      artistic_music_appreciation: null,
      artistic_music_ensemble: null,
      personal_cooperation: null,
      personal_responsibility: null,
      personal_character: null,
      personal_study: null,
      challenge_arts: null,
      challenge_academics: null,
      access_humanities: null,
      access_smath: null,
      integrated_experience: null,
      engages_arts_school: null,
      engages_arts_home: null,
      distance_participate: null,
      distance_tech: null,
      distance_num_show_livestream: null,
      count_performance: null,
      count_information: null,
      count_student_led: null,
      count_field_trip: null,
      count_college: null,
      count_conference: null,
      count_reading: null,
      yn_calendar: null,
      yn_calendar_subscribe: null,
      yn_blackboard: null,
      yn_recommend: null,
      yn_chromebook: null,
      yn_hotspot: null,
      yn_enrichment: null,
      yn_parent_symposium: null,
      yn_student_conference: null,
      yn_readings: null,
      yn_online_groups: null,
      why_renarts: "",
      comments: "",
    }
  }),
  methods: {
    getData: function() {
      this.$store.dispatch("enrollment/getSurvey")
        .then( () => {
          for(const p in this.survey) {
            this.survey[p] = Object.prototype.hasOwnProperty.call(this.$store.state["enrollment"].survey, p) ? this.$store.state["enrollment"].survey[p] : this.survey[p]
          }
        })
        .catch( error => console.log(error) )
    },
    postForm: function() {
      if(!this.valid) {
        this.$refs.form.validate()
        this.$store.dispatch("snackbar/snack", {text: this.$i18n.t("validation.invalid"), color:"error"})
        return
      }
      this.$store.dispatch("enrollment/postSurvey", this.survey)
        .then( () => {
          this.$store.dispatch("enrollment/postPacket", {survey: true})
        })
        .then( () => {
          this.$router.push({name: "EnrollmentSpecialEducation" })
        })
        .catch( error => {
          if(error.data.errors) {
            const errors = _.reduce(error.data.errors, (result, value) => (result+value+" "), "")
            this.$store.dispatch("snackbar/snack", {text: errors, color:"error"})
          }
        })
    },
    editForm: function() {
      this.$store.dispatch("enrollment/postPacket", {survey: false})
        .catch( error => {
          if(error.data.errors) {
            const errors = _.reduce(error.data.errors, (result, value) => (result+value+" "), "")
            this.$store.dispatch("snackbar/snack", {text: errors, color:"error"})
          }
        })
    }
  },
  mounted: function() {
    this.getData();
  },
  mixins: [
    validationMixin,
  ]
}
</script>
