<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("medical.title") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("medical.info") }}
      </v-card-subtitle>
      <v-card-text>
        <v-form
          v-model="valid"
          @submit.prevent="postForm"
          ref="form"
          :disabled="$store.getters['enrollment/packet'].medical"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="medical.doctor"
                :label="$t('medical.doctor')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="medical.phone"
                :label="$t('medical.phone')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="medical.insurance"
                :label="$t('medical.insurance')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="medical.allergies"
                :label="$t('medical.allergies')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="medical.medication"
                :label="$t('medical.medication')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['enrollment/packet'].complete"
          color="primary"
          disabled
        >
          {{ $t("actions.completedDone") }}
        </v-btn>
        <v-btn
          v-else-if="$store.getters['enrollment/packet'].medical"
          @click="editForm"
          color="primary"
          outlined
        >
          {{ $t("actions.completedEdit") }}
        </v-btn>
        <v-btn
          @click="postForm"
          color="primary"
          v-else
        >
          {{ $t("actions.verifyAndContinue") }} <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash'
import validationMixin from "@/mixins/validation.js"

export default {
  name: 'enrollment-medical',
  data: () => ({
    loading: true,
    valid: false,
    medical: {
      id: null,
      doctor: '',
      phone: '',
      insurance: '',
      allergies: '',
      medication: '',
    },
  }),
  methods: {
    getData: function() {
      this.$store.dispatch("enrollment/getMedical")
        .then( () => {
          for(const p in this.medical) {
            this.medical[p] = Object.prototype.hasOwnProperty.call(this.$store.state["enrollment"].medical, p) ? this.$store.state["enrollment"].medical[p] : this.medical[p]
          }
        })
        .catch( error => console.log(error) )
    },
    postForm: function() {
      if(!this.valid) {
        this.$refs.form.validate()
        this.$store.dispatch("snackbar/snack", {text: this.$i18n.t("validation.invalid"), color:"error"})
        return
      }
      this.$store.dispatch("enrollment/postMedical", this.medical)
        .then( () => {
          this.$store.dispatch("enrollment/postPacket", {medical: true})
        })
        .then( () => {
          this.$router.push({name: "EnrollmentFamily" })
        })
        .catch( error => {
          if(error.data.errors) {
            const errors = _.reduce(error.data.errors, (result, value) => (result+value+" "), "")
            this.$store.dispatch("snackbar/snack", {text: errors, color:"error"})
          }
        })
    },
    editForm: function() {
      this.$store.dispatch("enrollment/postPacket", {medical: false})
        .catch( error => {
          if(error.data.errors) {
            const errors = _.reduce(error.data.errors, (result, value) => (result+value+" "), "")
            this.$store.dispatch("snackbar/snack", {text: errors, color:"error"})
          }
        })
    }
  },
  mounted: function() {
    this.getData();
  },
  computed: {
    phoneTypes: function() {
      return [
        {value: "cell", text: this.$i18n.t("address.cell")},
        {value: "home", text: this.$i18n.t("address.home")},
        {value: "work", text: this.$i18n.t("address.work")}
      ];
    },
  },
  mixins: [
    validationMixin,
  ]
}
</script>
