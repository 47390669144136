<template>
  <v-icon v-if="done" color="success">mdi-check-circle</v-icon>
  <v-icon v-else color="error">mdi-close-circle</v-icon>
</template>

<script>

export default {
  name: "complete-indicator",
  props: {
    done: {type: Boolean, default: false}
  }
}
</script>
