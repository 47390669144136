import * as API from "./API.js";

export default {
  getPacket(userId, filter = {}) {
    return API.apiClient.get("users/" + userId + "/enrollment-packets", {
      params: { filter: filter },
    });
  },
  getDemographics(userId, filter = {}) {
    return API.apiClient.get("users/" + userId + "/demographic-records", {
      params: { filter: filter },
    });
  },
  getAddress(userId, filter = {}) {
    return API.apiClient.get("users/" + userId + "/address-records", {
      params: { filter: filter },
    });
  },
  getMedical(userId, filter = {}) {
    return API.apiClient.get("users/" + userId + "/medical-records", {
      params: { filter: filter },
    });
  },
  getNslp(userId, filter = {}) {
    return API.apiClient.get("users/" + userId + "/nslp-surveys", {
      params: { filter: filter },
    });
  },
  getSurvey(userId, filter = {}) {
    return API.apiClient.get("users/" + userId + "/parent-surveys", {
      params: { filter: filter },
    });
  },
  postPacket(userId, packetId, payload) {
    const method = packetId ? { _method: "patch" } : {};
    return API.apiClient.post(
      "users/" + userId + "/enrollment-packets/" + (packetId || ""),
      { ...payload, ...method }
    );
  },
  postDemographics(userId, recordId, payload) {
    const method = recordId ? { _method: "patch" } : {};
    return API.apiClient.post(
      "users/" + userId + "/demographic-records/" + (recordId || ""),
      { ...payload, ...method }
    );
  },
  postAddress(userId, recordId, payload) {
    const method = recordId ? { _method: "patch" } : {};
    return API.apiClient.post(
      "users/" + userId + "/address-records/" + (recordId || ""),
      { ...payload, ...method }
    );
  },
  postMedical(userId, recordId, payload) {
    const method = recordId ? { _method: "patch" } : {};
    return API.apiClient.post(
      "users/" + userId + "/medical-records/" + (recordId || ""),
      { ...payload, ...method }
    );
  },
  postNslp(userId, nslpId, payload) {
    const method = nslpId ? { _method: "patch" } : {};
    return API.apiClient.post(
      "users/" + userId + "/nslp-surveys/" + (nslpId || ""),
      { ...payload, ...method }
    );
  },
  postSurvey(userId, surveyId, payload) {
    const method = surveyId ? { _method: "patch" } : {};
    return API.apiClient.post(
      "users/" + userId + "/parent-surveys/" + (surveyId || ""),
      { ...payload, ...method }
    );
  },
};
