<template>
  <v-btn @click="logout" min-width="100px">
    <v-progress-circular v-if="authLoading"
      indeterminate
    ></v-progress-circular>
    <template v-else>
      {{ $t("general.logout") }}
    </template>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "logout-button",
  computed: {
    ...mapGetters("auth", ["authLoading"]),
  },

  methods: {
    logout: function() {
      if(this.authLoading) {
        return;
      }
      this.$store.dispatch("auth/logout", this.form)
        .finally(() => {
          this.$store.dispatch("enrollment/resetState")
          .finally(() => {
            this.$router.push({name: "Login"})
          })
        })
    }
  },

}
</script>
