<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("scholar_info.title") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("scholar_info.info") }}
      </v-card-subtitle>
      <v-card-text>
        <v-form
          v-model="valid"
          @submit.prevent="postForm"
          ref="form"
          :disabled="$store.getters['enrollment/packet'].scholar_info"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="demographics.nickname"
                :label="$t('demographics.nickname')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="demographics.gender"
                :items="genderTypes"
                :label="$t('demographics.gender')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              align="center"
              class="text-h6"
            >
              {{ $t("home.address") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="home.street"
                :label="$t('address.street')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="home.street2"
                :label="$t('address.street2')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="home.city"
                :label="$t('address.city')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="home.state"
                :label="$t('address.state')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="home.zip"
                :label="$t('address.zip')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              align="center"
              class="text-h6"
            >
              {{ $t("home.car") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="home.license"
                :label="$t('address.license')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="home.insurance"
                :label="$t('address.insurance')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['enrollment/packet'].complete"
          color="primary"
          disabled
        >
          {{ $t("actions.completedDone") }}
        </v-btn>
        <v-btn
          v-else-if="$store.getters['enrollment/packet'].scholar_info"
          @click="editForm"
          color="primary"
          outlined
        >
          {{ $t("actions.completedEdit") }}
        </v-btn>
        <v-btn
          @click="postForm"
          color="primary"
          v-else
        >
          {{ $t("actions.verifyAndContinue") }} <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash'
import validationMixin from "@/mixins/validation.js"
import typesMixin from "@/mixins/types.js"

export default {
  name: 'enrollment-home',
  data: () => ({
    loading: true,
    valid: false,
    demographics: {
      id: null,
      nickname: '',
      gender: '',
      pronouns: '',
    },
    home: {
      id: null,
      type: 'home',
      street: '',
      street2: '',
      city: '',
      state: 'CA',
      zip: '',
      license: '',
      insurance: '',
    },
  }),
  methods: {
    getData: function() {
      this.$store.dispatch("enrollment/getScholarInfo")
        .then( () => {
          for(const p in this.home) {
            this.home[p] = Object.prototype.hasOwnProperty.call(this.$store.state["enrollment"].home, p) ? this.$store.state["enrollment"].home[p] : this.home[p]
          }
          for(const p in this.demographics) {
            this.demographics[p] = Object.prototype.hasOwnProperty.call(this.$store.state["enrollment"].demographics, p) ? this.$store.state["enrollment"].demographics[p] : this.demographics[p]
          }
        })
        .catch( error => console.log(error) )
    },
    postForm: function() {
      if(!this.valid) {
        this.$refs.form.validate()
        this.$store.dispatch("snackbar/snack", {text: this.$i18n.t("validation.invalid"), color:"error"})
        return
      }
      this.$store.dispatch("enrollment/postScholarInfo", {"demographics": this.demographics, "home": this.home})
        .then( () => {
          this.$store.dispatch("enrollment/postPacket", {scholar_info: true})
          this.$store.dispatch("auth/setNickname", this.demographics.nickname)
        })
        .then( () => {
          this.$router.push({name: "EnrollmentMail" })
        })
        .catch( error => {
          if(error.data.errors) {
            const errors = _.reduce(error.data.errors, (result, value) => (result+value+" "), "")
            this.$store.dispatch("snackbar/snack", {text: errors, color:"error"})
          }
        })
    },
    editForm: function() {
      this.$store.dispatch("enrollment/postPacket", {scholar_info: false})
        .catch( error => {
          if(error.data.errors) {
            const errors = _.reduce(error.data.errors, (result, value) => (result+value+" "), "")
            this.$store.dispatch("snackbar/snack", {text: errors, color:"error"})
          }
        })
    }
  },
  mounted: function() {
    this.getData();
  },
  mixins: [
    validationMixin,
    typesMixin,
  ]
}
</script>
