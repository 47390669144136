<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("is_contract.title") }}
      </v-card-title>
      <v-card-text>
        <p>
          <b>{{ $t('is_contract.name') }}</b> {{ authUser.nickname }} {{ authUser.lname }}
        </p>
        <div class="text-h6 mb-2">{{ $t("is_contract.subtitle") }}</div>
        <div class="text-body-1">
          <b>{{ $t('is_contract.p1_title') }}</b> {{ $t('is_contract.p1') }} <small>{{ $t('is_contract.p1_ec') }}</small>
        </div>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p2_title') }}</b> {{ $t('is_contract.p2') }}
        </p>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p3_title') }}</b> {{ $t('is_contract.p3') }}
          <ul>
            <li><b>{{ $t('is_contract.p3_li1_title') }}</b> {{ $t('is_contract.p3_li1') }}</li>
            <li><b>{{ $t('is_contract.p3_li2_title') }}</b> {{ $t('is_contract.p3_li2') }}</li>
            <li><b>{{ $t('is_contract.p3_li3_title') }}</b> {{ $t('is_contract.p3_li3') }}</li>
          </ul>
        </p>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p4_title') }}</b> {{ $t('is_contract.p4') }} <small>{{ $t('is_contract.p4_ec') }}</small>
        </p>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p5_title') }}</b> {{ $t('is_contract.p5') }} <small>{{ $t('is_contract.p5_ec') }}</small>
        </p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{ $t('is_contract.t1h1c1') }}</th>
                <th>{{ $t('is_contract.t1h1c2') }}</th>
                <th>{{ $t('is_contract.t1h1c3') }}</th>
                <th>{{ $t('is_contract.t1h1c4') }}</th>
                <th>{{ $t('is_contract.t1h1c5') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t('is_contract.t1r1c1') }}</td>
                <td>{{ $t('is_contract.t1r1c2') }}</td>
                <td>{{ $t('is_contract.t1r1c3') }}</td>
                <td>{{ $t('is_contract.t1r1c4') }}</td>
                <td>{{ $t('is_contract.t1r1c5') }}</td>
              </tr>
              <tr>
                <td>{{ $t('is_contract.t1r2c1') }}</td>
                <td>{{ $t('is_contract.t1r2c2') }}</td>
                <td>{{ $t('is_contract.t1r2c3') }}</td>
                <td>{{ $t('is_contract.t1r2c4') }}</td>
                <td>{{ $t('is_contract.t1r2c5') }}</td>
              </tr>
              <tr>
                <td>{{ $t('is_contract.t1r3c1') }}</td>
                <td>{{ $t('is_contract.t1r3c2') }}</td>
                <td>{{ $t('is_contract.t1r3c3') }}</td>
                <td>{{ $t('is_contract.t1r3c4') }}</td>
                <td>{{ $t('is_contract.t1r3c5') }}</td>
              </tr>
              <tr>
                <td>{{ $t('is_contract.t1r4c1') }}</td>
                <td>{{ $t('is_contract.t1r4c2') }}</td>
                <td>{{ $t('is_contract.t1r4c3') }}</td>
                <td>{{ $t('is_contract.t1r4c4') }}</td>
                <td>{{ $t('is_contract.t1r4c5') }}</td>
              </tr>
              <tr>
                <td>{{ $t('is_contract.t1r5c1') }}</td>
                <td>{{ $t('is_contract.t1r5c2') }}</td>
                <td>{{ $t('is_contract.t1r5c3') }}</td>
                <td>{{ $t('is_contract.t1r5c4') }}</td>
                <td>{{ $t('is_contract.t1r5c5') }}</td>
              </tr>
              <tr>
                <td>{{ $t('is_contract.t1r6c1') }}</td>
                <td>{{ $t('is_contract.t1r6c2') }}</td>
                <td>{{ $t('is_contract.t1r6c3') }}</td>
                <td>{{ $t('is_contract.t1r6c4') }}</td>
                <td>{{ $t('is_contract.t1r6c5') }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p6_title') }}</b> {{ $t('is_contract.p6') }}
          <ul>
            <li>{{ $t('is_contract.p6_li1') }}</li>
            <li>{{ $t('is_contract.p6_li2') }}</li>
            <li>{{ $t('is_contract.p6_li3') }}</li>
          </ul>
        </p>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p7_title') }}</b> {{ $t('is_contract.p7') }} <small>{{ $t('is_contract.p7_ec') }}</small>
        </p>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p8_title') }}</b> {{ $t('is_contract.p8') }}
          <ul>
            <li>{{ $t('is_contract.p8_li1') }}</li>
            <li>{{ $t('is_contract.p8_li2') }}</li>
            <li>{{ $t('is_contract.p8_li3') }}</li>
          </ul>
        </p>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p9_title') }}</b> {{ $t('is_contract.p9') }}
        </p>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p10_title') }}</b> {{ $t('is_contract.p10') }}
        </p>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p11_title') }}</b> {{ $t('is_contract.p11') }}
        </p>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p12_title') }}</b> {{ $t('is_contract.p12') }}
        </p>
        <p class="text-body-1 mt-2">
          <b>{{ $t('is_contract.p13_title') }}</b> {{ $t('is_contract.p13') }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['enrollment/packet'].is"
          color="primary"
          disabled
        >
          {{ $t("actions.completed") }}
        </v-btn>
        <v-btn @click="postForm" color="primary" v-else>
          {{ $t("actions.isAndContinue") }}
          <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "enrollment-is",
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters("auth", ["authUser"]),
  },
  methods: {
    postForm: function () {
      this.$store
        .dispatch("enrollment/postPacket", { is: true })
        .then(() => {
          this.$router.push({ name: "EnrollmentSignature" });
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
