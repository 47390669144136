<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("signature.title") }} for 2024-25 School Year
      </v-card-title>
      <v-card-subtitle>
        {{ $t("signature.info") }}
      </v-card-subtitle>
      <v-card-text>
        <v-form
          v-model="valid"
          @submit.prevent="postForm"
          ref="form"
          :disabled="$store.getters['enrollment/packet'].complete"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="signature.signature"
                :label="$t('signature.signature')"
                :rules="validationRules('required')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <p v-if="this.packet.signature_timestamp">
          Signed on {{ this.packet.signature_timestamp }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['enrollment/packet'].complete"
          color="primary"
          disabled
        >
          {{ $t("actions.completedDone") }}
        </v-btn>
        <v-btn @click="postForm" color="primary" v-else>
          {{ $t("actions.sign") }}
          <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import validationMixin from "@/mixins/validation.js";
import { mapState } from "vuex";

export default {
  name: "enrollment-signature",
  data: () => ({
    loading: true,
    valid: false,
    signature: {
      signature: "",
    },
  }),
  methods: {
    complete: function () {
      return (
        this.packet.introduction &&
        this.packet.scholar_info &&
        this.packet.mail_address &&
        this.packet.p1_address &&
        this.packet.p2_address &&
        this.packet.e1_address &&
        this.packet.e2_address &&
        this.packet.bb_address &&
        this.packet.pu1_address &&
        this.packet.pu2_address &&
        this.packet.medical &&
        this.packet.nslp &&
        (this.packet.survey ||
          this.$store.getters["auth/authUser"].is_first_year) &&
        this.packet.mcd &&
        this.packet.is
      );
    },
    getData: function () {
      this.signature.signature =
        this.packet.signature || this.signature.signature;
    },
    postForm: function () {
      if (!this.valid) {
        this.$refs.form.validate();
        this.$store.dispatch("snackbar/snack", {
          text: this.$i18n.t("validation.invalid"),
          color: "error",
        });
        return;
      }
      if (!this.complete()) {
        this.$store.dispatch("snackbar/snack", {
          text: this.$i18n.t("validation.incomplete"),
          color: "error",
        });
        return;
      }
      this.$store
        .dispatch("enrollment/postPacket", {
          ...this.signature,
          complete: true,
        })
        .then(() => {
          this.$router.push({ name: "EnrollmentDone" });
        })
        .catch((error) => console.log(error));
    },
  },
  mounted: function () {
    this.getData();
  },
  computed: {
    ...mapState("enrollment", ["packet"]),
  },
  watch: {
    packet() {
      this.signature.signature =
        this.packet.signature || this.signature.signature;
    },
  },
  mixins: [validationMixin],
};
</script>
