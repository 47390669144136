import axios from "axios"
import store from "../store"

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    post: {        // can be common or any other method
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  },
});

apiClient.interceptors.request.use(
  function(config) {
    const token = window.localStorage.getItem("token")
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config;
  },
  function(error) {
    return Promise.reject(error.response)
  }
);

apiClient.interceptors.response.use(
  response => {
    return response
  },
  function(error) {
    console.log(error)
    if (error.response.status === 401) {
      store.dispatch("auth/clear")
    }
    return Promise.reject(error.response)
  }
);
