import Vue from "vue"
import Vuex from "vuex"

import * as auth from "@/store/modules/auth.js";
import enrollment from "@/store/modules/enrollment.js";
import * as snackbar from "@/store/modules/snackbar.js";

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,

  modules: {
    auth,
    enrollment,
    snackbar,
  }
})
